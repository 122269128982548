import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <h2>Hello World!</h2>
      </header>
    </div>
  );
}

export default App;
